<template>
  <section>
    <!-- <div class="group" v-if="tabName == 'last-step'">
        <div class="group-header">
            <div class="group-title">Покупатель</div>
            <div class="group-edit">
                <a class="edit" href="#">
                    <svg class="icon" width="15.966" height="16" viewBox="0 0 15.966 16">
                        <use xlink:href="@/assets/img/icons.svg#edit"></use>
                    </svg>
                </a>
            </div>
        </div>
        <div class="group-body">
            <div class="row row--checkout-buyer">
                <div class="col">
                    <div class="label">ФИО</div>
                    <p>{{ order.fio }}</p>
                </div>
                <div class="col">
                    <div class="label">E-mail</div>
                    <p>{{ order.email }}</p>
                </div>
                <div class="col">
                    <div class="label">Телефон</div>
                    <p>{{ order.phone }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="group" v-if="tabName != 'pay'">
        <div class="group-header">
            <div class="group-title">Оплата</div>
            <div class="group-edit">
                <a class="edit" @click="push('basket/checkout/pay')">
                    <svg class="icon" width="15.966" height="16" viewBox="0 0 15.966 16">
                        <use xlink:href="@/assets/img/icons.svg#edit"></use>
                    </svg>
                </a>
            </div>
        </div>
        <div class="group-body">
            <div class="row row--checkout-group-payment">
                <div class="col">
                    <div class="label">Тип оплаты</div>
                    <p>{{ getNameById(payTypes, order.pay_type) }}</p>
                </div>
                <div class="col" v-if="order.pay_type != 20">
                    <div class="row row--payment-cards">
                        <div class="col"><img src="@/assets/img/payment-visa.svg" alt=""></div>
                        <div class="col"><img src="@/assets/img/payment-mastercard.svg" alt=""></div>
                        <div class="col"><img src="@/assets/img/payment-paykeeper.svg" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="group" v-if="tabName != 'delivery'">
        <div class="group-header">
            <div class="group-title">Доставка</div>
            <div class="group-edit">
                <a class="edit" @click="push('basket/checkout/delivery')">
                    <svg class="icon" width="15.966" height="16" viewBox="0 0 15.966 16">
                        <use xlink:href="@/assets/img/icons.svg#edit"></use>
                    </svg>
                </a>
            </div>
        </div>
        <div class="group-body">
            <div class="row row--checkout-group">
                <div class="col" v-if="order.delivery_type == 10">
                    <div class="label">Адрес пункта самовывоза</div>
                    <p v-if="currentAddress">{{ currentAddress.address }}</p>
                </div>
                <div class="col" v-if="order.delivery_type == 20">
                    <div class="label">Адрес доставки</div>
                    <p>{{ order.delivery_address }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="group" v-if="tabName != 'region'">
        <div class="group-header">
            <div class="group-title">Регион доставки</div>
            <div class="group-edit">
                <a class="edit" @click="push('basket/checkout/region')">
                    <svg class="icon" width="15.966" height="16" viewBox="0 0 15.966 16">
                        <use xlink:href="@/assets/img/icons.svg#edit"></use>
                    </svg>
                </a>
            </div>
        </div>
        <div class="group-body">
            <div class="row row--checkout-group">
                <div class="col">
                    <div class="label">Тип плательщика</div>
                    <p>{{ accountTypes[order.account_type] }}</p>
                </div>
                <div class="col">
                    <div class="label">Местоположение</div>
                    <p>{{ order.location }}</p>
                </div>
                <div class="col">
                    <div class="label">Индекс</div>
                    <p>{{ order.postcode }}</p>
                </div>
            </div>
        </div>
    </div> -->

    <div class="group">
        <div class="group-header">
            <div class="group-title">Товары в заказе</div>
        </div>
        <div class="group-body">
            <div class="cart">
                <div class="cart-list">
                    <div class="cart-item cart-item--labels">
                        <div class="cart-row cart-row--labels">
                            <div class="cart-cell">
                                <div class="label">Наименование</div>
                            </div>
                            <div class="cart-cell">
                                <div class="label">Количество</div>
                            </div>
                            <div class="cart-cell">
                                <div class="label">Сумма</div>
                            </div>
                            <div class="cart-cell cart-cell--remove">
                                <div class="label">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-item" v-for="position in order.positions" :key="position.id">
                        <div class="cart-row">
                            <div class="cart-cell">
                                <div class="cart-cell-label">
                                    <div class="label">Наименование</div>
                                </div>
                                <div class="cart-product">
                                    <!-- <div class="cart-product-image">
                                        <img :src="yandexCloud + position.link_img" alt="">
                                    </div> -->
                                    <div class="cart-product-title">{{ position.name }}</div>
                                </div>
                            </div>
                            <div class="cart-cell cart-count">
                                <div class="cart-cell-label">
                                    <div class="label">Количество</div>
                                </div>
                                {{ position.amount }} шт
                            </div>
                            <div class="cart-cell cart-price" v-if="regionCurrency[regionCode]">
                                <div class="cart-cell-label">
                                    <div class="label">Сумма</div>
                                </div>
                                <b>{{ position.sell }} {{ regionCurrency[regionCode].currency }}</b>
                            </div>
                            <div class="cart-cell cart-cell--remove">
                                <button class="cart-remove" @click="deletePosition(position.id)">Удалить</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
        tabName: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/order',
            payTypes: 'dictionary/payTypes',
            accountTypes: 'dictionary/accountType',
            yandexCloud: 'app/yandexCloud',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            regionAdresses: 'app/regionAdresses',
            region:'auth/city',
        }),
        currentAddress() {
            let address = null
            this.regionAdresses.map((element) => {
                if (element.id_1c == this.region) {
                    address = element
                }
            })
            return address
        },
    },
    methods: {
        ...Vuex.mapActions({
            deletePosition: 'basket/deletePosition',
        }),
    }
}
</script>