import Http from "../utils/http"
import Config from "../config"

const state = {
    message: null,
    form: {
        excludedIds: [],
        ids: [],
        limit: 100,
        page: 1,
        phone: "",
        query: "",
        root_1c_id: "",
        email: "",
        utm: ""
    },
    isAuth: false,
    messages: [],
}

const getters = {
    message: state => state.message,
    form: state => state.form,
    isAuth: state => state.isAuth,
    messages: state => state.messages,
    url: state => state.url,
}

const actions = {
    getMessages: ({commit}, user_id) => {
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/message", {user_id: user_id})
                .then(response => {
                    commit("setMessages", response.data)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    readMessages: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            Http.put(Config.apiUrl + "/v1/message/mark_as_read", payload)
                .then(response => {
                    commit("setMessage", 'notifications success readed')
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    deleteMessages: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            Http.delete(Config.apiUrl + "/v1/message/delete", {data: payload})
                .then(response => {
                    commit("setMessages", response.data)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    createMessages: ({commit}, payload) => {
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/message/create", payload)
                .then(response => {
                    commit("setMessage", 'success create message')
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setMessages: (state, val) => {
        let array = []
        val.map((el) => {
            if (!el.deleted_at) {
                array.push(el)
            }
        })
        val = array
        val.sort((a, b) => Number(b.id) - Number(a.id))
        state.messages = val
    },
    setIsAuth: (state, val) => {
        state.isAuth = val
    },
    pushMessage: (state, val) => {
        state.messages.unshift(val)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
