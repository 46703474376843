<template>
    <div class="cart-item">
        <div class="cart-row">
            <div class="cart-cell">
                <div class="cart-cell-label">
                    <div class="label">Наименование</div>
                </div>
                <div class="cart-product">
                    <div class="cart-product-title"><a :href="'/cardProduct/' + currentProduct.id" target="_blank">{{ position.name }}</a></div>
                </div>
            </div>
            <div class="cart-cell max-w12">
                <div class="cart-cell-label">
                    <div class="label">Цвет</div>
                </div>
                <div v-if="!isEmptyObject(currentColor)" class="cart-product">
                    <ul class="list list--flex layout-colors">
                        <li 
                            class="list-item"  
                        >
                            <label class="color">
                                <input 
                                    type="radio"
                                    name="color"
                                    checked="true"
                                >
                                <span 
                                    v-if="currentColor.code"
                                    class="color-preview" 
                                    :style="'border-color: ' + currentColor.code + '; background: ' + currentColor.code + ';'"
                                >
                                    <span 
                                        :style="'border-color: ' + currentColor.code + ';'"
                                    />
                                </span>
                                <img 
                                    v-else 
                                    class="color-preview color_img_replace_span" 
                                    :src="yandexCloud + currentColor.link"
                                >
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="cart-cell" v-if="regionCurrency[regionCode]">
                <div class="cart-cell-label">
                    <div class="label">Розничная цена</div>
                </div>
                <b>{{ getFixedPrice(position.price) }} {{ regionCurrency[regionCode].currency }}/{{ position.base_unit_of_measure }}</b>
                <!-- <div class="label">цена за 1 лист</div> -->
            </div>
            <div class="cart-cell">
                <div class="cart-cell-label">
                    <div class="label">Количество </div>
                </div>
                <div class="counter">
                    <button class="counter-minus" @click="changeAmount('minus')">-</button>
                    <input 
                        type="text" 
                        max="9999"
                        v-model="editPosition.amount"
                        @keyup="changeAmount"
                        @blur="checkAmount"
                    >
                    <button class="counter-plus" @click="changeAmount('plus')">+</button>
                </div>
            </div>
            <div class="cart-cell max-w12 cart-price" v-if="regionCurrency[regionCode]">
                <div class="cart-cell-label">
                    <div class="label">Сумма</div>
                </div>
                <b>{{ position.sell }} {{ regionCurrency[regionCode].currency }}</b>
            </div>
            <div class="cart-cell cart-cell--remove">
                <button class="cart-remove" @click="deletePosition(position.id)">Удалить</button>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    props: {
        position: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            currentColor: {},
            currentProduct: {},
        }
    },
    computed: {
        ...Vuex.mapGetters({
            yandexCloud: 'app/yandexCloud',
            region: 'auth/city',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            userCompanyId: 'auth/userCompanyId',
        }),
        editPosition() {
            return Object.assign({}, this.position)
        }
    },
    created() {
        this.editPosition.root_id = this.region
        this.getCurrentColor()
        this.getCurrentProduct()
    },
    methods: {
        ...Vuex.mapActions({
            deletePosition: 'basket/deletePosition',
            updatePosition: 'basket/updatePosition',
            getOneColor: 'catalog/getOneColor',
            searchNavbar: 'catalog/searchNavbar',
        }),
        getFixedPrice(price) {
            let fixedPrice = 0
            if (price) {
                fixedPrice = ( (price.toString().includes('.')) ? (price.toString().split('.').pop().length) : (0) )
            }
            return (fixedPrice >= 2 ? price.toFixed(2) : price)
        },
        getCurrentColor() {
            if (this.position.color_external != null) {
                this.getOneColor(this.position.color_external)
                    .then((response) => {
                        this.currentColor = response.data[0]
                    })
            }
        },
        getCurrentProduct() {
            if (this.position.id_1c) {
                this.searchNavbar({id_1c: this.position.id_1c, root_id: this.region})
                    .then((response) => {
                        this.currentProduct = response.data[0]
                    })
            }
        },
        changeAmount(type) {
            if (type == 'minus') {
                if (this.editPosition.amount == 1) {
                    return
                }
                this.editPosition.amount--
            }else if (type == 'plus') {
                if (this.editPosition.amount == 50) {
                    return
                }
                this.editPosition.amount++
                
            }
            this.editPosition.amount = Number(this.editPosition.amount)
            if (this.editPosition.amount > 9999) {
                this.editPosition.amount = 9999
            }
            this.editPosition.root_id = this.region
            this.editPosition.company_id = Number(this.userCompanyId)
            this.updatePosition({ position: this.editPosition })
        },
        checkAmount() {
            if (!this.editPosition.amount && this.editPosition.amount == 0) {
                this.editPosition.amount = 1
                this.editPosition.root_id = this.region
                this.editPosition.company_id = Number(this.userCompanyId)
                this.updatePosition({ position: this.editPosition })
            }else if (!this.editPosition.amount && this.editPosition.amount > 9999) {
                this.editPosition.amount = 9999
                this.editPosition.root_id = this.region
                this.editPosition.company_id = Number(this.userCompanyId)
                this.updatePosition({ position: this.editPosition })
            }
        },
    },
}
</script>
<style lang="scss">
    .cart-product{
        .color{
            width: 7.9rem;
            height: 4.2rem;
            margin-right: 2.5rem;
            cursor: unset;
        }
    }
</style>