<template>
    <main class="admin-index index__admin admin-offers-page" style="display: block !important;">
        <div class="admin-index_header">
            <h3>Акции и предложения</h3>
        </div>

        <div 
            v-if="!isShowForm" 
            class="index__admin"
        >
            <button @click="changeForm(true)">
                Добавить акцию
            </button>
        </div>

        <div class="input-block" style="margin-top: 30px; margin-bottom: 30px;">
            <label for="">Текст сообщения в уведомлении</label>
            <input type="text" v-model="formMessageData.message">
            <p style="margin-left: 0;">Измените этот текст перед созданием акции или изменением существующей!</p>
        </div>

        <create-offer 
            v-if="isShowForm" 
            @changeForm="changeForm" 
            @createNotif="createNotif"
        />

        <div 
            v-for="(obj, ind) in uiPromotions" 
            :key="obj.id"
        >
            <offer-single 
                :key="obj.id"
                :index="ind"
                :element="obj"
                @createNotif="createNotif"
            />
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import offerSingle from './singleOffer.vue'
import 'vue2-datepicker/index.css';
import createOffer from './createOffer.vue'

export default {
    components: {
        offerSingle,
        createOffer,
    },
    data() {
        return {
            isShowForm: false,
            formMessage: {
                user_id: null,
                type: 60,
                data: null,
            },
            formMessageData: {
                id: null,
                message: 'Ознакомьтесь с новыми акционными предложениями!',
                link: null,
                description: null,
            },
        }
    },
    computed: {
        ...Vuex.mapGetters({
            offers: 'app/offers',
            fileUrl: 'app/fileUrl',
            user: 'auth/user',
            uiPromotions: 'ui_promotion/promotions',
        }),
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
            createMessages: 'notif/createMessages',
        }),
        ...Vuex.mapMutations({
            pushOffer: 'app/pushOffer',
        }),
        changeForm(val) {
            this.isShowForm = val
        },
        createNotif() {
            if (!this.formMessageData.message) {
                return
            }
            let payload = Object.assign({}, this.formMessage)
            payload.data = JSON.stringify(this.formMessageData)
            payload.user_id = this.user.id
            this.createMessages(payload)
        },
    },
}
</script>
