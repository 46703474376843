<template>
    <div class="order-table">
        <div class="order-table_headers">
            <p style="width: 10%; text-align: center;">Номер документа</p>

            <div class="sub_table" style="width: 70%;">
                <p style="width: 44%; text-align: center;">Состав по номенклатуре</p>
                <p style="width: 14%; text-align: center;">Ед.изм.</p>
                <p style="width: 14%; text-align: center;">Кол-во</p>
                <p style="width: 14%; text-align: center;">Дата по графику</p>
                <p style="width: 14%; text-align: center;">Подразделение</p>
            </div>

            <p style="width: 10%; text-align: center;">Сумма</p>
            <p style="width: 10%; text-align: center;">Доставка Дата</p>
        </div>
        <div class="order-table_rows">
            <div 
                v-for="position, idx in inProduction"
                :key="idx"
                class="order-table_rows-single"
            >
                <div class="details">
                    <div style="width: 10%; font-weight: 700;">{{ position.document_number }}</div>
                    
                    <div  
                        class="sub_table"
                        style="width: 70%; flex-wrap: wrap;"
                    >
                        <div 
                            v-for="sub, idx in position.sub_table"
                            :key="idx"
                            class="single_sub_table" 
                            style="width: 100%;"
                        >
                            <div style="width: 44%;">{{ JSON.parse(sub).nomenclature }}</div>
                            <div style="width: 14%; text-align: center;">{{ JSON.parse(sub).unit }}</div>
                            <div style="width: 14%; text-align: center;">{{ JSON.parse(sub).amount }}</div>
                            <div style="width: 14%; text-align: center;">{{ getParsedDate(JSON.parse(sub).scheduled_date) }}</div>
                            <div style="width: 14%; text-align: center;">{{ JSON.parse(sub).subdivision }}</div>
                        </div>
                    </div>
                    <div style="width: 10%; text-align: center;">{{ position.sum }}</div>
                    <div style="width: 10%; text-align: center;">{{ getTextDeliveryType(position.delivery_type) }} <br> {{ getParsedDate(position.delivery_at) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        inProduction: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        getParsedDate(date) {
            if (!date) {
                return null
            }
            let parsed = date.split('-')
            let parsedDay = parsed[2].split('T')
            return parsedDay[0] + '-' + parsed[1] + '-' + parsed[0]
        },
        getTextDeliveryType(type) {
            let val = null
            if (type == 10) {
                val = 'Самовывоз'
            }else if (type == 20) {
                val = 'Доставка'
            }
            return val
        },
    },
}
</script>
<style lang="scss">
    .single_sub_table{
        margin: 20px 0;
        display: flex;
        align-items: center;
    }
</style>