<template>
    <div class="lk-instructions-page">
        <p class="lk-page_instructions-header">Документы</p>
        <div class="lk-instructions_content">
            <div v-if="companies.length != 0" class="inst_once">
                <div class="inst_row">
                    <p>Акт сверки</p>
                    <button class="download_button" @click="openModal" style="padding: 10px 37px;">
                        <p>Запросить</p>
                    </button>
                </div>
            </div>
            <div class="inst_once">
                <div class="inst_row">
                    <p>Прайс-лист</p>
                    <button @click="downloadPriceList" class="download_button">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="white"/>
                        </svg>

                        <p>
                            Скачать
                        </p>
                    </button>
                </div>
            </div>
            <div class="inst_once">
                <div @click="isShowCert = !isShowCert" class="inst_row">
                    <p>Сертификаты </p>
                    <svg v-if="!isShowCert" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7083 15.7064C12.5208 15.8938 12.2665 15.9992 12.0013 15.9992C11.7362 15.9992 11.4818 15.8938 11.2943 15.7064L5.63732 10.0494C5.54181 9.95712 5.46563 9.84678 5.41322 9.72477C5.36081 9.60277 5.33322 9.47155 5.33207 9.33877C5.33092 9.20599 5.35622 9.07431 5.4065 8.95141C5.45678 8.82852 5.53103 8.71686 5.62492 8.62297C5.71882 8.52908 5.83047 8.45483 5.95337 8.40454C6.07626 8.35426 6.20794 8.32896 6.34072 8.33012C6.4735 8.33127 6.60472 8.35886 6.72672 8.41126C6.84873 8.46367 6.95907 8.53986 7.05132 8.63537L12.0013 13.5854L16.9513 8.63537C17.1399 8.45321 17.3925 8.35241 17.6547 8.35469C17.9169 8.35697 18.1677 8.46214 18.3531 8.64755C18.5385 8.83296 18.6437 9.08377 18.646 9.34597C18.6483 9.60816 18.5475 9.86076 18.3653 10.0494L12.7083 15.7064Z" fill="black"/>
                    </svg>

                    <svg v-if="isShowCert" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_25037)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2917 8.29363C11.4792 8.10616 11.7335 8.00085 11.9987 8.00085C12.2638 8.00085 12.5182 8.10616 12.7057 8.29363L18.3627 13.9506C18.4582 14.0429 18.5344 14.1532 18.5868 14.2752C18.6392 14.3972 18.6668 14.5285 18.6679 14.6612C18.6691 14.794 18.6438 14.9257 18.5935 15.0486C18.5432 15.1715 18.469 15.2831 18.3751 15.377C18.2812 15.4709 18.1695 15.5452 18.0466 15.5955C17.9237 15.6457 17.7921 15.671 17.6593 15.6699C17.5265 15.6687 17.3953 15.6411 17.2733 15.5887C17.1513 15.5363 17.0409 15.4601 16.9487 15.3646L11.9987 10.4146L7.04868 15.3646C6.86008 15.5468 6.60748 15.6476 6.34528 15.6453C6.08308 15.643 5.83227 15.5379 5.64686 15.3525C5.46145 15.167 5.35628 14.9162 5.35401 14.654C5.35173 14.3918 5.45252 14.1392 5.63468 13.9506L11.2917 8.29363Z" fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_25037">
                                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div v-if="isShowCert" class="instruction_list">
                    <div v-for="certOne in documentCertArray" :key="certOne.id" class="inst_download_once">
                        <p>{{ certOne.name }}</p>
                        <button @click="openPdfPreviewModal(certOne.link)">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="white"/>
                            </svg>

                            <p>
                                Скачать
                            </p>
                        </button>
                    </div>
                </div>
            </div>
            <div class="inst_once">
                <div @click="isShowGarantTalon = !isShowGarantTalon" class="inst_row">
                    <p>Гарантийные талоны</p>
                    <svg v-if="!isShowGarantTalon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7083 15.7064C12.5208 15.8938 12.2665 15.9992 12.0013 15.9992C11.7362 15.9992 11.4818 15.8938 11.2943 15.7064L5.63732 10.0494C5.54181 9.95712 5.46563 9.84678 5.41322 9.72477C5.36081 9.60277 5.33322 9.47155 5.33207 9.33877C5.33092 9.20599 5.35622 9.07431 5.4065 8.95141C5.45678 8.82852 5.53103 8.71686 5.62492 8.62297C5.71882 8.52908 5.83047 8.45483 5.95337 8.40454C6.07626 8.35426 6.20794 8.32896 6.34072 8.33012C6.4735 8.33127 6.60472 8.35886 6.72672 8.41126C6.84873 8.46367 6.95907 8.53986 7.05132 8.63537L12.0013 13.5854L16.9513 8.63537C17.1399 8.45321 17.3925 8.35241 17.6547 8.35469C17.9169 8.35697 18.1677 8.46214 18.3531 8.64755C18.5385 8.83296 18.6437 9.08377 18.646 9.34597C18.6483 9.60816 18.5475 9.86076 18.3653 10.0494L12.7083 15.7064Z" fill="black"/>
                    </svg>

                    <svg v-if="isShowGarantTalon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_25037)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2917 8.29363C11.4792 8.10616 11.7335 8.00085 11.9987 8.00085C12.2638 8.00085 12.5182 8.10616 12.7057 8.29363L18.3627 13.9506C18.4582 14.0429 18.5344 14.1532 18.5868 14.2752C18.6392 14.3972 18.6668 14.5285 18.6679 14.6612C18.6691 14.794 18.6438 14.9257 18.5935 15.0486C18.5432 15.1715 18.469 15.2831 18.3751 15.377C18.2812 15.4709 18.1695 15.5452 18.0466 15.5955C17.9237 15.6457 17.7921 15.671 17.6593 15.6699C17.5265 15.6687 17.3953 15.6411 17.2733 15.5887C17.1513 15.5363 17.0409 15.4601 16.9487 15.3646L11.9987 10.4146L7.04868 15.3646C6.86008 15.5468 6.60748 15.6476 6.34528 15.6453C6.08308 15.643 5.83227 15.5379 5.64686 15.3525C5.46145 15.167 5.35628 14.9162 5.35401 14.654C5.35173 14.3918 5.45252 14.1392 5.63468 13.9506L11.2917 8.29363Z" fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_25037">
                                <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 24 24)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div v-if="isShowGarantTalon" class="instruction_list">
                    <div v-for="garantTalonOne in documentGarantTalonArray" :key="garantTalonOne.id" class="inst_download_once">
                        <p>{{ garantTalonOne.name }}</p>
                        <button @click="openPdfPreviewModal(garantTalonOne.link)">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 16L7 11L8.4 9.55L11 12.15V4H13V12.15L15.6 9.55L17 11L12 16ZM6 20C5.45 20 4.97933 19.8043 4.588 19.413C4.19667 19.0217 4.00067 18.5507 4 18V15H6V18H18V15H20V18C20 18.55 19.8043 19.021 19.413 19.413C19.0217 19.805 18.5507 20.0007 18 20H6Z" fill="white"/>
                            </svg>

                            <p>
                                Скачать
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <act-modal v-if="isShowModal" @closeModal="closeModal" />
    </div>
</template>
<script>
import Vuex from 'vuex'
import actModal from '@/components/modals/lkDocumentAct.vue'

export default {
    components: {
        actModal,
    },
    data() {
        return {
            isShowModal: false,
            isShowCert: false,
            isShowGarantTalon: false,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            documents: 'ui_document/documents',
            yandexCloud: 'app/yandexCloud',
            region: 'auth/city',
            topLevelCategory: 'dictionary/topLevelCategory',
            user: 'auth/user',
            companies: 'companies/currentCompanies',
        }),
        currentRegion() {
			let region = null
			if (this.region) {
				region = this.region
			}else{
				if (this.topLevelCategory.length != 0) {
					region = this.topLevelCategory[0].id_1c
				}
			}
			return region
		},
        documentRulesArray() {
            let array = []
            this.documents.map((element) => {
                if (element.category == 5) {
                    array.push(element)
                }
            })
            return array
        },
        documentCertArray() {
            let array = []
            this.documents.map((element) => {
                if (element.category == 4) {
                    array.push(element)
                }
            })
            return array
        },
        documentGarantTalonArray() {
            let array = []
            this.documents.map((element) => {
                if (element.category == 7) {
                    array.push(element)
                }
            })
            return array
        },
        documentInstArray() {
            let array = []
            this.documents.map((element) => {
                if (element.category == 3) {
                    array.push(element)
                }
            })
            return array
        },
        documentType() {
            return this.$route.params.id
        },
    },
    watch: {
        documentType() {
            this.checkType()
        },
    },
    mounted() {
        this.getDocuments()
        setTimeout(() => {
            this.checkType()
        }, 1000)
    },
    methods: {
        ...Vuex.mapActions({
            getDocuments: 'ui_document/getDocuments',
        }),
        openPdfPreviewModal(link) {
            window.open(link, '_blank')
        },
        checkType() {
            if (this.documentType) {
                if (this.documentType == 'certificates') {
                    this.isShowCert = true
                }
                if (this.documentType == 'guaranteestalon') {
                    this.isShowGarantTalon = true
                }
                if (this.documentType == 'act' && this.companies.length != 0) {
                    this.isShowModal = true
                }
                if (this.documentType == 'price') {
                    this.downloadPriceList()
                }
            }
        },  
        downloadPriceList() {
            let link = ''
            if (this.user.account_type == 30 && this.user.id_1c) {
                link = this.yandexCloud + 'prices/' + this.user.id_1c + '/price.pdf'
            }else{
                link = this.yandexCloud + 'prices/' + this.currentRegion + '/price.pdf'
            }
            if (!link) {
                return
            }
            window.open(link, '_blank')
        },
        closeModal() {
            this.isShowModal = false
        },
        openModal() {
            this.isShowModal = true
        },
        onClickOutside() {
            console.log('asd')
            this.closeModal()
        },
    },
}
</script>
<style lang="scss">
    .lk-instructions-page{
        .lk-page_instructions-header{
            margin-top: 50px;
            margin-bottom: 30px;

            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
            color: black;
            margin-bottom: 30px;
        }
        .lk-instructions_content{
            .inst_once{
                .inst_row{
                    padding: 17.5px 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:hover{
                        cursor: pointer;
                    }

                    p{
                        color: black;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 33px;
                    }

                    .download_button{
                        background-color: #171717;
                        display: flex;
                        gap: 24px;
                        align-items: center;
                        padding: 10px 24px;  
                        border: unset;
                        border-radius: 10px;
                        
                        p{
                            color: #fff;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 27px;
                        }
                    }
                }

                .instruction_list{
                    padding: 40px;

                    .inst_download_once{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 20px;

                        p{
                            color: black;
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 33px;
                        }

                        button{
                            background-color: #171717;
                            display: flex;
                            gap: 24px;
                            align-items: center;
                            padding: 10px 24px;  
                            border: unset;
                            border-radius: 10px;

                            p{
                                color: #fff;
                                font-weight: 700;
                                font-size: 16px;
                                line-height: 27px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>