<template>
    <div class="lk-notifications-page">
        <div class="lk-page_notifications-header_buttons" id="block_notifications_list">
            <p class="lk-page_notifications-header">Уведомления</p>

            <div class="lk-page_notifications-header_buttons_right-part">
                <button 
                    v-if="idsNewNotifications.length != 0"
                    class="button button--default add_button"
                    @click="read" 
                >
                    Прочитать все уведомления
                </button>
    
                <svg @click="deleteM" @mouseenter="isShowTooltip = true" @mouseleave="isShowTooltip = false" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="black"/>
                </svg>

                <div v-if="isShowTooltip" class="tooltip-custom">
                    <p>Удалить все уведомления</p>
                </div>
            </div>
        </div>

        <div class="lk-notifications-page-list">
            <div 
                v-for="notif, idx in messages.slice((page-1) * limitPage, page * limitPage)" 
                :key="notif.id" 
                class="lk-notifications-page-list_once" 
                :style="idx % 2 != 1 ? 'background-color: #F5F6FA' : ''"
            >
                <div class="lk-notifications-page-list_once-header_block">
                    <p>{{ foramtDateFromISO(notif.created_at) }}</p>
                    <svg v-if="!notif.is_reader" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="6" cy="6" r="6" fill="#FFCE6F"/>
                    </svg>
                </div>

                <p class="lk-notifications-page-list_once-message">{{ isValidJsonParse(notif.data) ? JSON.parse(notif.data).message : notif.data }}</p>
                <p class="lk-notifications-page-list_once-link" v-if="getLinkName(notif.type)" @click="pushToNotif(notif)">{{ getLinkName(notif.type) }}</p>
            </div>
        </div>
        <v-pagination
            v-if="amountPages > 1"
            class="custom_pagination"
            v-model="page"
            :page-count="amountPages"
        ></v-pagination>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import vPagination from 'vue-plain-pagination'

export default {
    mixins: [Functions],
    components: {
        vPagination,
    },
    data() {
        return {
            isShowTooltip: false,
            limitPage: 10,
            page: 1,
            total: 0,
        }
    },
    watch: {
        page() {
            this.scrollToTop()
        },
    },
    computed: {
        ...Vuex.mapGetters({
            messages: 'notif/messages',
            user: 'auth/user',
        }),
        amountPages() {
            let count = 1
            count = Math.ceil(this.messages.length / this.limitPage)
            return count
        },
        idsNewNotifications() {
            let array = []
            this.messages.map((element) => {
                if (!element.is_reader) {
                    array.push(element.id)
                }
            })
            return array
        },
    },
    methods: {
        ...Vuex.mapActions({
            readMessages: 'notif/readMessages',
            getMessages: 'notif/getMessages',
            deleteMessages: 'notif/deleteMessages',
            getOneById1c: 'basket/getOneById1c',
        }),
        getLinkName(type) {
            let name = null
            if (type == 10) {
                name = 'Сотрудники'
            } else if (type == 15) {
                name = 'Сотрудники'
            } else if (type == 20 || type == 30) {
                name = 'Детали заказа'
            } else if (type == 50) {
                name = 'Скачать акт сверки'
            } else if (type == 60) {
                name = 'Акции'
            } else {
                name = 'Неизвестный тип уведомления'
            }
            return name
        },
        scrollToTop() {
            let element = document.getElementById('block_notifications_list')
            let top = element.offsetTop
            window.scrollTo(0, top)
        },
        read() {
            this.readMessages({ids: this.idsNewNotifications})
                .then(() => {
                    this.getMessages(this.user.id)
                })
        },
        readOnce(id) {
            this.readMessages({ids: [id]})
                .then(() => {
                    this.getMessages(this.user.id)
                })
        },
        deleteM() {
            let ids = []
            this.messages.map((element) => {
                ids.push(element.id)
            })
            this.deleteMessages({user_id: this.user.id, ids: ids})
                .then(() => {
                    this.getMessages(this.user.id)
                })
        },
        pushToNotif(notif) {
            let id = null
            let notif_data = null
            if (this.isValidJsonParse(notif.data)) {
                notif_data = JSON.parse(notif.data)
                id = notif_data.id
            }else{
                notif_data = notif.data
            }
            if ((notif.type == 10 || notif.type == 15) && id) {
                this.readMessages({ids: [notif.id]})
                    .then(() => {
                        this.getMessages(this.user.id)
                        this.push('lk/employees/' + id)
                    })
            }else if((notif.type == 20 || notif.type == 30) && id) {
                this.readMessages({ids: [notif.id]})
                    .then(() => {
                        this.getMessages(this.user.id)
                        this.getOneById1c(id)
                            .then((response) => {
                                if (response.data.length != 0) {
                                    this.push('lk/single-order/' + response.data[0].id + '?history=true')
                                }else{
                                    this.push('lk/single-order/' + id + '?history=true')
                                }
                            })
                    })
            }else if (notif.type == 50 && notif.link){
                this.readMessages({ids: [notif.id]})
                    .then(() => {
                        this.getMessages(this.user.id)
                        this.push(notif.link)
                    })
            }else if (notif.type == 60){
                this.readMessages({ids: [notif.id]})
                    .then(() => {
                        this.getMessages(this.user.id)
                        this.push('offers')
                    })
            }
        },
    },
}
</script>