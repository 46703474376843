export default {
    methods: {
        getLocalImage(str) {
            return require('../assets/img/' + str)
        },
        push(str) {
            this.$router.push('/' + str).catch(() => {})
        },
        getNameById(array, id) {
            let name = null
            array.map((element) => {
                if (element.id == id) {
                    name = element.name
                }
            })
            return name
        },
        isEmptyObject(obj) {
            for (var i in obj) {
                return false;
            }
            return true;
        },
        getNormalFormatedDate(date) {
            let response
            let splited_date = date.split('-')
            let splitedDay = splited_date[2].split('T')
            response = splitedDay[0] + '.' + splited_date[1] + '.' + splited_date[0]
            return response
        },
        isEmptyElement(element) {
            let response = false
            if (Array.isArray(element) && element.length == 0) {
                response = true
            }else if (element == null || element == undefined) {
                response = true
            }
            return response
        },
        getTrueBigSum(price) {
            let num = 0
            let fixedPrice = 0
            if (price) {
                num = ( (price.toString().includes('.')) ? (price.toString().split('.').pop().length) : (0) )
            }
            fixedPrice = num >= 2 ? price.toFixed(2) : price

            let parts = fixedPrice.toString().split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            return parts.join(".")
        },
        formatedPhone(phone) {
            let masked = ''

            var finallyPhone = ''
            var splitedPhone = phone.split('')
            splitedPhone.map((element) => {
                if (!isNaN(element) && element != ' ') {
                    finallyPhone += element
                }
            }) 
            
            if (finallyPhone[0] == '+' && finallyPhone[1] == '7') {
                masked += finallyPhone.substring(0, 1)
                masked += ' (' + finallyPhone.substring(1, 4) + ') ' 
                masked += finallyPhone.substring(4, 7) 
                masked += '-' + finallyPhone.substring(7) 
            }else if (finallyPhone[0] == '7') {
                masked += '+' + finallyPhone.substring(0, 1) 
                masked += ' (' + finallyPhone.substring(1, 4) + ') ' 
                masked += finallyPhone.substring(4, 7) 
                masked += '-' + finallyPhone.substring(7) 
            }else{
                masked += '+7'
                masked += ' (' + finallyPhone.substring(0, 3) + ') ' 
                masked += finallyPhone.substring(3, 6) 
                masked += '-' + finallyPhone.substring(6)   
            } 
            return masked
        },
        foramtDateFromISO(date, symbol_in) {
            let formated_date = ''
            let only_date = ''
            let symbol_def = '-'
            if (symbol_in) {
                symbol_def = symbol_in
            }
            if (date) {
                formated_date = date.split('T')
                only_date = formated_date[0].split('-')
            }
            return only_date[2] + symbol_def + only_date[1] + symbol_def + only_date[0]
        },
        formatedTime(s) {
            let m = Math.trunc(s / 60) + ''
            s = (s % 60) + ''
            return m.padStart(2, 0) + ':' + s.padStart(2, 0)
        },
        isValidJsonParse(jsonString) {
            var json
            try {
                json = JSON.parse(jsonString)
            } catch (exception) {
                json = null
            }

            if (json) {
                return true
            }

            return false
        },
    }
}