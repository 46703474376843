import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    message: null,
    messageStatus: null,
    users: [],
    form: {
        email: "",
        excludedIds: [],
        id_1c: "",
        ids: [],
        inn: "",
        kpp: "",
        limit: null,
        name: "",
        page: 1,
        query: "",
        root_id: ""
    },
    companies: [],
    isShowModalUser: false,
    currentCompanies: [],
    deleteCompanyId: null,
    editCompanyElement: {},
    usersCompany: [],
    currentCompany: {},
    innCompany: {},
    deleteEmployeeId: null,
    linkObject: {},
    companiesByInn: [],
    requestAccessForm: {},
}

const getters = {
    message: state => state.message,
    messageStatus: state => state.messageStatus,
    users: state => state.users,
    form: state => state.form,
    companies: state => state.companies,
    isShowModalUser: state => state.isShowModalUser,
    currentCompanies: state => state.currentCompanies,
    deleteCompanyId: state => state.deleteCompanyId,
    editCompanyElement: state => state.editCompanyElement,
    usersCompany: state => state.usersCompany,
    currentCompany: state => state.currentCompany,
    innCompany: state => state.innCompany,
    deleteEmployeeId: state => state.deleteEmployeeId,
    linkObject: state => state.linkObject,
    companiesByInn: state => state.companiesByInn,
    requestAccessForm: state => state.requestAccessForm,
}

const actions = {
    getCompanies: ({commit, getters}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        let isAuthenticated = store.getters['auth/isAuthenticated']
        if (!isAuthenticated) {
            return 
        }
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/company/admin", getters.form, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCompanies", response.data)
                    resolve(response)
                })
        })
    },
    getUsersByCompany: ({commit}, company_id) => {
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/user_link_company/" + company_id)
                .then(response => {
                    commit("setMessage", 'get users by company success')
                    resolve(response)
                })
        })
    },
    deleteUser: ({commit}, id) => {
        return new Promise((resolve) => {
            Http.delete(Config.apiUrl + "/v1/user_link_company/" + id)
                .then(response => {
                    commit("setMessage", 'user deleted')
                    resolve(response)
                })
        })
    },
    createUser: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/create", payload)
                .then(response => {
                    commit("setMessage", 'user created')
                    resolve(response)
                })
        })
    },
    search: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/company/admin", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCurrentCompanies", response.data)
                    resolve(response)
                })
        })
    },
    create: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/company/admin/create", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success create company')
                    resolve(response)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    delete: ({commit}, id_company) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/" + id_company, { deleted_at: null, access: 60 }, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success create company')
                    resolve(response)
                })
        })
    },
    searchUsers: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/company/admin/user", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setUsersCompany", response.data)
                    resolve(response)
                })
        })
    },
    getOne: ({commit}, company_id) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/company/admin", {ids: [company_id]}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setCurrentCompany", response.data)
                    resolve(response)
                })
        })
    },
    addEmployee: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/send_user", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success add user to company')
                    resolve(response)
                })
        })
    },
    confirmEmployee: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/add_user", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success add user to company')
                    resolve(response)
                })
        })
    },
    editCompany: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.patch(Config.apiUrl + "/v1/company/admin/" + payload.id, payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setMessage", 'success edit company')
                    resolve(response)
                })
        })
    },
    getCompanyByInn: ({commit}, inn) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.get(Config.apiUrl + "/v1/company/admin/" + inn, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setInnCompany", response.data)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                    commit("setInnCompany", {})
                })
        })
    },
    deleteEmployee: ({commit}, link_id) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/" + link_id, {deleted_at: null, access: 60}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setInnCompany", response.data)
                    resolve(response)
                })
        })
    },
    changeAccess: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/" + payload.id, {deleted_at: payload.date, access: payload.access}, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setInnCompany", response.data)
                    resolve(response)
                })
        })
    },
    addIssetCompany: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve, reject) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/create", payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setInnCompany", response.data)
                    resolve(response)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    searchCompanyByInn: ({commit}, payload) => {
        let string = ''
        if (payload.inn) {
            string = 'inn=' + payload.inn
        } else {
            return false
        }

        if (payload.kpp) {
            string += '&kpp=' + payload.kpp
        }

        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/dadata/find_inn?" + string)
                .then(response => {
                    commit("setCompaniesInn", response.data)
                    resolve(response)
                })
                .catch(() => {
                    commit("setInnCompany", [])
                })
        })
    },
    checkIssetCompanyByInnKpp: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + "/v1/company/admin/" + payload.inn + '/kpp/' + payload.kpp)
                .then(response => {
                    commit("setMessage", 'succes')
                    resolve(response)
                })
        })
    },
    requestAccess: ({commit}, payload) => {
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + "/v1/user_link_company/request_access", payload)
                .then(response => {
                    console.log(response.data, 'check response on request access')
                    commit("setMessage", 'succes')
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setMessage: (state, val) => {
        state.message = val
    },
    setCompanies: (state, val) => {
        if (val) {
            val.sort((a, b) => Number(a.id_1c) - Number(b.id_1c))
        }else{
            val = []
        }
        state.companies = val
    },
    updateForm: (state, val) => {
        state.form = val
    },
    setShowModalUser: (state, val) => {
        state.isShowModalUser = val
    },
    setCurrentCompanies: (state, val) => {
        if (!val) {
            val = []
        }
        state.currentCompanies = val
    },
    setDeleteCompanyId: (state, val) => {
        state.deleteCompanyId = val
    },
    setEditCompanyElement: (state, val) => {
        state.editCompanyElement = val
    },
    setUsersCompany: (state, val) => {
        state.usersCompany = val
    },
    setCurrentCompany: (state, val) => {
        if (Array.isArray(val) && val[0]) {
            state.currentCompany = val[0]
        }else{
            state.currentCompany = val
        }
    },
    setInnCompany: (state, val) => {
        state.innCompany = val
    },
    setCompaniesInn: (state, val) => {
        state.companiesByInn = val
    },
    setDeleteEmployeeId: (state, val) => {
        state.deleteEmployeeId = val
    },
    setLinkObject: (state, val) => {
        state.linkObject = val
    },
    setRequestAccessForm: (state, val) => {
        state.requestAccessForm = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
