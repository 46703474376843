<template>
    <div class="modal-iframe lk-companies-modal" :class="step == 4 ? 'lk-companies-modal-w' : ''">
        <div class="modal modal--form modal--opened modal--visible" id="modal_callback">
            <div class="modal-container">
                <div class="modal-body">
                    <div class="modal-header-block" :style="step == 5 && isSuccess ? 'align-items: self-start;' : ''">
                        <p class="modal-header" v-if="step != 5 && step != 6">Добавить компанию</p>
                        <p class="modal-header modal-header-success" v-if="step == 5 && isSuccess">Организация добавлена!</p>
                        <p class="modal-header modal-header-error" v-if="step == 5 && !isSuccess">Ошибка! Попробуйте позже или свяжитесь с менеджером</p>
                        <p class="modal-header" v-if="step == 6">Получение доступа</p>

                        <svg @click="close" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.3002 5.71022C18.2077 5.61752 18.0978 5.54397 17.9768 5.49379C17.8559 5.44361 17.7262 5.41778 17.5952 5.41778C17.4643 5.41778 17.3346 5.44361 17.2136 5.49379C17.0926 5.54397 16.9827 5.61752 16.8902 5.71022L12.0002 10.5902L7.11022 5.70022C7.01764 5.60764 6.90773 5.5342 6.78677 5.4841C6.6658 5.43399 6.53615 5.4082 6.40522 5.4082C6.27429 5.4082 6.14464 5.43399 6.02368 5.4841C5.90272 5.5342 5.79281 5.60764 5.70022 5.70022C5.60764 5.79281 5.5342 5.90272 5.4841 6.02368C5.43399 6.14464 5.4082 6.27429 5.4082 6.40522C5.4082 6.53615 5.43399 6.6658 5.4841 6.78677C5.5342 6.90773 5.60764 7.01764 5.70022 7.11022L10.5902 12.0002L5.70022 16.8902C5.60764 16.9828 5.5342 17.0927 5.4841 17.2137C5.43399 17.3346 5.4082 17.4643 5.4082 17.5952C5.4082 17.7262 5.43399 17.8558 5.4841 17.9768C5.5342 18.0977 5.60764 18.2076 5.70022 18.3002C5.79281 18.3928 5.90272 18.4662 6.02368 18.5163C6.14464 18.5665 6.27429 18.5922 6.40522 18.5922C6.53615 18.5922 6.6658 18.5665 6.78677 18.5163C6.90773 18.4662 7.01764 18.3928 7.11022 18.3002L12.0002 13.4102L16.8902 18.3002C16.9828 18.3928 17.0927 18.4662 17.2137 18.5163C17.3346 18.5665 17.4643 18.5922 17.5952 18.5922C17.7262 18.5922 17.8558 18.5665 17.9768 18.5163C18.0977 18.4662 18.2076 18.3928 18.3002 18.3002C18.3928 18.2076 18.4662 18.0977 18.5163 17.9768C18.5665 17.8558 18.5922 17.7262 18.5922 17.5952C18.5922 17.4643 18.5665 17.3346 18.5163 17.2137C18.4662 17.0927 18.3928 16.9828 18.3002 16.8902L13.4102 12.0002L18.3002 7.11022C18.6802 6.73022 18.6802 6.09022 18.3002 5.71022Z" fill="black"/>
                        </svg>
                    </div>
                    <div v-if="step == 4 || step == 5 || step == 6" class="modal-subheader-block">
                        <p class="modal-subheader" v-if="step == 4">Ваша организация уже зарегистрирована в нашей системе. Мы предоставили вам актуальные данные, которые были найдены. В случае несоответствия информации, вы можете внести изменения в личном кабинете или связаться с менеджером для ее корректировки.</p>
                        <p class="modal-subheader" v-if="step == 5 && isSuccess">У вас будет возможность формировать новые заказы на данную компанию, просматривать историю заказов и отслеживать статус всех текущих заказов. </p>
                        <p class="modal-subheader" v-if="step == 6">Ваш запрос направлен ответственному менеджеру. Ожидайте обратную связь.</p>
                    </div>
                    <div v-if="step == 4" class="modal-text-block">
                        <div class="modal-text-ones">
                            <p class="modal-text-name">Название организации</p>
                            <p class="modal-text-value">{{ innCompany.name ? innCompany.name : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">Юридический адрес</p>
                            <p class="modal-text-value">{{ innCompany.adress ? innCompany.adress : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">Фактический адрес</p>
                            <p class="modal-text-value">{{ innCompany.actual_address ? innCompany.actual_address : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">ИНН</p>
                            <p class="modal-text-value">{{ innCompany.inn ? innCompany.inn : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">КПП</p>
                            <p class="modal-text-value">{{ innCompany.kpp ? innCompany.kpp : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">ОГРН</p>
                            <p class="modal-text-value">{{ innCompany.ogrn ? innCompany.ogrn : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">Наименование банка</p>
                            <p class="modal-text-value">{{ innCompany.bank ? innCompany.bank : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">Расчетный счет</p>
                            <p class="modal-text-value">{{ innCompany.pc ? innCompany.pc : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">БИК банка</p>
                            <p class="modal-text-value">{{ innCompany.bik ? innCompany.bik : 'Не указано' }}</p>
                        </div>
                        <div class="modal-text-ones">
                            <p class="modal-text-name">Корреспондентский счет</p>
                            <p class="modal-text-value">{{ innCompany.kc ? innCompany.kc : 'Не указано' }}</p>
                        </div>
                    </div>
                    <div v-if="step != 4 && step != 5 && step != 6" class="modal-input-block">
                        <p class="modal-input-label">ИНН</p>
                        <input type="text" class="modal-input" placeholder="ИНН" v-model="form.inn" maxlength="10" @keyup="checkInputInn" @keypress.enter="serchByInn" @paste="innPaste">
                        <div v-if="isShowCompaniesList && lastChanged == 'inn'" class="modal-companies-list">
                            <div @click="setCompanyFromList(ccc)" v-for="ccc in companiesByInn" :key="ccc.id" class="modal-companies-ones">
                                <p>
                                    {{ ccc.name }}
                                </p>
                                <p>ИНН: {{ ccc.inn }}, КПП: {{ ccc.kpp }}</p>
                                <p>{{ ccc.adress }}</p>
                            </div>
                            <div @click="nextStep('otherCompany')" class="modal-companies-ones">
                                <p>
                                    Другой филиал
                                </p>
                                <p>
                                    Выберите, если ИНН введен правильно, но вашего филиала нет в списке
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="step == 2 || step == 3" class="modal-input-block">
                        <p class="modal-input-label">КПП</p>
                        <input type="text" class="modal-input" placeholder="КПП" v-model="form.kpp" maxlength="9" @keyup="checkInputKpp" @keypress.enter="serchByInn" @paste="kppPaste">
                        <div v-if="isShowCompaniesList && lastChanged == 'kpp'" class="modal-companies-list">
                            <div @click="setCompanyFromList(ccc)" v-for="ccc in companiesByInn" :key="ccc.id" class="modal-companies-ones">
                                <p>
                                    {{ ccc.name }}
                                </p>
                                <p>ИНН: {{ ccc.inn }}, КПП: {{ ccc.kpp }}</p>
                                <p>{{ ccc.adress }}</p>
                            </div>
                            <div @click="nextStep('otherCompany')" class="modal-companies-ones">
                                <p>
                                    Другой филиал
                                </p>
                                <p>
                                    Выберите, если ИНН введен правильно, но вашего филиала нет в списке
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">ОГРН</p>
                        <input type="text" class="modal-input" placeholder="ОРГН" v-model="form.ogrn">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">Наименование организации</p>
                        <input type="text" class="modal-input" placeholder="Наименование организации" v-model="form.name">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">Юридический адрес</p>
                        <input type="text" class="modal-input" placeholder="Юридический адрес" v-model="form.adress">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">Фактический адрес</p>
                        <input type="text" class="modal-input" placeholder="Фактический адрес" v-model="form.actual_address">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">Расчетный счет</p>
                        <input type="text" class="modal-input" placeholder="Расчетный счет" v-model="form.pc">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">БИК банка</p>
                        <input type="text" class="modal-input" placeholder="БИК банка" v-model="form.bik">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">Наименование банка</p>
                        <input type="text" class="modal-input" placeholder="Наименование банка" v-model="form.bank">
                    </div>
                    <div v-if="step == 3" class="modal-input-block">
                        <p class="modal-input-label">Корреспондентский счет</p>
                        <input type="text" class="modal-input" placeholder="Корреспондентский счет" v-model="form.kc">
                    </div>
                    <div class="modal-button-block" v-if="step != 3 && step != 4 && step != 5 && step != 6">
                        <button class="button button--primary" @click="nextStep" :disabled="step == 1 && form.inn.length < 10 ? true : false">Добавить</button>
                    </div>
                    <div class="modal-button-block" v-if="step == 3">
                        <button class="button button--primary" @click="save">Добавить</button>
                    </div>
                    <div class="modal-button-block" v-if="step == 4">
                        <button class="button button--primary" @click="saveIsset">Добавить</button>
                    </div>
                    <div class="modal-button-block" v-if="step == 5 && isSuccess && saveCompanyIssetOrNo == 10">
                        <button class="button button--default" @click="close">Получить позже</button>
                        <button class="button button--primary" @click="request">Запросить доступ</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            form: {
                inn: '',
                kpp: null,
                ogrn: null,
                name: null,
                adress: null,
                actual_address: null,
                pc: null,
                bik: null,
                bank: null,
                kc: null,
                id_1c: "",
            },
            lastChanged: 'inn',
            isShowCompaniesList :false,
            step: 1,
            isSuccess: true,
            newLink: {},
            saveCompanyIssetOrNo: 10,
            issetCompanyId: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            companies: 'companies/companies',
            innCompany: 'companies/innCompany',
            user: 'auth/user',
            companiesByInn: 'companies/companiesByInn',
            requestAccessForm: 'companies/requestAccessForm',
        }),
    },
    mounted() {
        this.getCompanies()
    },
    methods: {
        ...Vuex.mapMutations({
            setInnCompany: 'companies/setInnCompany',
            setMessage: 'auth/setMessage',
            setRequestAccessForm: 'companies/setRequestAccessForm',
        }),
        ...Vuex.mapActions({
            createCompany: 'companies/create',
            getCompanies: 'companies/getCompanies',
            getCompanyByInn: 'companies/getCompanyByInn',
            addIssetCompany: 'companies/addIssetCompany',
            searchCompanyByInn: 'companies/searchCompanyByInn',
            checkIssetCompanyByInnKpp: 'companies/checkIssetCompanyByInnKpp',
            requestAccess: 'companies/requestAccess',
        }),
        close() {
            this.$emit('closeAddModal')
        },
        saveIsset() {
            this.saveCompanyIssetOrNo = 10

            this.addIssetCompany({
                user_id: this.user.id,
                company_id: this.innCompany.id,
                access: 70,
                deleted_at: null, 
            })
                .then((response) => {
                    this.step = 5
                    this.newLink = response.data
                    this.isSuccess = true
                })
                .catch(() => {
                    this.step = 5
                    this.isSuccess = false
                })
        },
        save() {
            this.saveCompanyIssetOrNo = 20

            this.createCompany(this.form)
                .then(() => {
                    this.step = 5
                })
                .catch((error) => {
                    if (error.response.data.error == 'duplicate entry in the table') {
                        this.setMessage('Компания с таким ИНН и КПП уже существует!')
                        this.$emit('openErrorModal')
                    }
                })
        },
        setCompanyFromList(company) {
            this.checkIssetCompanyByInnKpp({inn: company.inn, kpp: company.kpp})
                .then((response) => {
                    if (response.data != 0) {
                        this.issetCompanyId = response.data
                        this.setInnCompany({
                            id: response.data,
                            kpp: company.kpp,
                            ogrn: company.ogrn,
                            name: company.name,
                            adress: company.address,
                            actual_address: company.actual_address,
                            pc: company.pc,
                            bik: company.bik,
                            bank: company.bank,
                            kc: company.kc,
                        })
                        this.step = 4
                    }else{
                        this.form.kpp = company.kpp
                        this.form.ogrn = company.ogrn
                        this.form.name = company.name
                        this.form.adress = company.address
                        this.form.actual_address = company.actual_address
                        this.form.pc = company.pc
                        this.form.bik = company.bik
                        this.form.bank = company.bank
                        this.form.kc = company.kc
                        if (this.step < 2) {
                            this.step = 2
                        }
                        this.isShowCompaniesList = false
                    }
                })
        },
        nextStep(string) {
            if (string && string == 'otherCompany') {
                this.form.ogrn = ''
                this.form.name = ''
                this.form.adress = ''
                this.form.actual_address = ''
                this.form.pc = ''
                this.form.bik = ''
                this.form.bank = ''
                this.form.kc = ''
            }

            if (this.step != 3) {
                this.step += 1
            }

            this.isShowCompaniesList = false
        },
        serchByInn() {
            this.searchCompanyByInn({inn: this.form.inn, kpp: this.form.kpp})
        },
        checkInputInn() {
            this.lastChanged = 'inn'
            if (this.form.inn.length == 10) {
                this.isShowCompaniesList = true
                this.serchByInn()
            }else{
                this.isShowCompaniesList = false
            }
        },
        checkInputKpp() {
            this.lastChanged = 'kpp'
            if (this.form.kpp.length == 9) {
                this.isShowCompaniesList = true
                this.serchByInn()
            }else{
                this.isShowCompaniesList = false
            }
        },
        innPaste() {
            this.lastChanged = 'inn'
            setTimeout(() => {
                this.isShowCompaniesList = true
                this.serchByInn()
            }, 100)
        },
        kppPaste() {
            this.lastChanged = 'kpp'
            setTimeout(() => {
                this.isShowCompaniesList = true
                this.serchByInn()
            }, 100)
        },
        request() {
            this.setRequestAccessForm({
                company_id: this.issetCompanyId,
                email: this.user.email,
                hash: null,
                midname: this.user.midname,
                name: this.user.name,
                surname: this.user.surname
            })

            this.requestAccess(this.requestAccessForm)
                .then(() => {
                    this.nextStep()
                })
        },
    }
}
</script>