<template>
    <div class="container-l mt-5 front_single_offer_page" style="margin-bottom: 100px;">
        <bread-crumbs :pages-array="arrayLinks" :withNdProp="false" />
        <div>
            <div class="col-12">
                <div class="section--no-pt">
                <div>
                    <h1 style="font-size: 3.2rem;">{{ element.name }}</h1>
                </div>
                </div>
                <div class="section--no-pt documents_page">
                    <div>
                        <div class="offer">
                            <div class="offer-image">
                                <img :src="element.linkimg" alt="">
                            </div>
                            <div class="offer-content list">
                                <h4>Подробности акции</h4>
                                <p v-html="element.details"></p>
                                <div class="document_card list-item" v-if="element.file_link">
                                    <a 
                                        target="_blank"
                                        :href="element.file_link"
                                    >
                                        <div class="new-preview-width">
                                            <img src="https://storage.yandexcloud.net/mayakprod/images/knjt0j7pHt.png" alt="" class="preview_img">
                                        </div>
                                        <div class="card-arrow">
                                            <svg 
                                                class="icon" 
                                                width="19" 
                                                height="19" 
                                                viewBox="0 0 19 19"
                                            >
                                            <use xlink:href="@/assets/img/icons.svg#arrow-top-right" />
                                            </svg>
                                        </div>
                                    </a>
                                </div>
                                <a class="button button--primary" @click="openModalApp">Отправить заявку</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <application v-if="app == true"/>
        </div>
    </div>
</template>
<script>
import application from '../../modals/application.vue'
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Vuex from 'vuex'
export default {
      mixins: [Functions],
    components:{
        application,
        breadCrumbs
    },
    data() {
        return {
            activeCategory: 0,
            object: {},
            element: {},
        }
    },
    watch:{
        uiPromotions(){
            this.getOnePromotion()
        },
    },
    computed: {
        ...Vuex.mapGetters({
            app:'appmodal/app',

            arrayLinks: 'ui_promotion/arrayLinks',
            uiPromotions: 'ui_promotion/promotions',
        }),
        
    },
    methods: {
         ...Vuex.mapMutations({
            setApp:'appmodal/setApp',
            setHrefApp:'appmodal/setHrefApp',
        }),
        getOnePromotion() {
            this.uiPromotions.map((el) => {
                if (el.link == this.$route.params.key) {
                    this.element = el
                }
            })
        },
        openModalApp(){
            this.setHrefApp(window.location.href)
            this.setApp(true) 
        },
    },
    created(){
        this.getOnePromotion()
    }
}
</script>
<style lang="scss">
    .mb-100{
        margin-bottom: 100px;
    }
    .front_single_offer_page{
        .documents_page {
            .document_card {
                .new-preview-width {
                    .preview_img{
                        width: 65px;
                        height: 65px;
                        min-height: 20px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                }

                .card-arrow{
                    top: 7px;
                    right: 7px;
                    
                    svg{
                        width: 13px;
                        height: 13px;
                    }
                }
            }
        }
    }
</style>