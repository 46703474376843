import Http from "../utils/http"
import Config from "../config"
import store from './index'

const state = {
    documentsCats: [
        { id: 1, name: "Лицензии", url: "litsenzii"},
        { id: 2, name: "Буклеты", url: "brochures"},
        { id: 3, name: "Инструкции", url: "instructions"},
        { id: 4, name: "Сертификаты качества", url: "certificates"},
        { id: 5, name: "Правила транспортировки", url: "rules"},
        { id: 6, name: "Гарантии", url: "guarantees"},
        { id: 7, name: "Гарантийные талоны", url: "guarantees"},
    ],
    offersRegion: [
        { id:0 , name:"Акция по всем регионам", text: 'Акция по всем регионам'},
        { id:1 , name:"Пермский край", text: 'Пермский край'},
        { id:2 , name:"Саратовская область", text: 'Саратовская область'},
        { id:3 , name:"Московская край", text: 'Московская край'},
    ],
    offersFrom: [
        { id: 0, name:"Акция для всех клиентов"},
        { id: 10, name:"Для частных лиц"},
        { id: 20, name:"Для юридических лиц"},
        { id: 30, name:"Для диллеров"},
       
    ],
    supportsType: [
        { id: 10, name:"Технические решения"},
        { id: 20, name:"Видео и учебные материалы"},
        { id: 30, name:"FAQ"},
    ],
    pagesDocuments: [
        {id: 0, name: "Документы", url: "documents", fullUrl: 'documents'},
        {id: 1, name: "Лицензии", url: "litsenzii", fullUrl: 'documents/litsenzii'},
        {id: 2, name: "Буклеты", url: "brochures", fullUrl: 'documents/brochures'},
        {id: 3, name: "Инструкции", url: "instructions", fullUrl: 'documents/instructions'},
        {id: 4, name: "Сертификаты качества", url: "certificates", fullUrl: 'documents/certificates'},
    ],
    pagesSupports: [
        {id: 0, name: "Поддержка", url: "support", fullUrl: 'support'},
        {id: 1, name: "Технические решения", url: "technicals", fullUrl: 'support/technicals'},
        {id: 2, name: "FAQ", url: "faq", fullUrl: 'support/faq'},
    ],
    pagesPartner: [
        {id: 0, name: "Стань партнером", url: "partner", fullUrl: 'partner'},
    ],
    pagesServices: [
        {id: 0, name: "Услуги", url: "services", fullUrl: 'services'},
    ],
    pagesLegals: [
        {id: 0, name: "Юрлицам", url: "legals", fullUrl: 'legals'},
    ],
    pagesAboutCompany: [
        {id: 0, name: "О компании", url: "aboutcompany", fullUrl: 'aboutcompany'},
        {id: 10, name: "Информация", url: "info", fullUrl: 'aboutcompany/info'},
        {id: 20, name: "Сэндвич-панели", url: "sp", fullUrl: 'aboutcompany/info/sp'},
        {id: 30, name: "Сэндвич-панели", url: "sp-evosteel", fullUrl: 'aboutcompany/info/sp-evosteel'},
        {id: 40, name: "Профилированный лист", url: "proflist", fullUrl: 'aboutcompany/info/proflist'},
    ],
    listBenefitTypes: [
        {id: 0, name: "Выберите роль"},
        {id: 10, name: "Дилер"},
        {id: 20, name: "Прораб"}
    ],
    pagesBasket: [
        {id: 0, name: "Корзина", url: "basket", fullUrl: 'basket'},
        {id: 1, name: "Оформление заказа", url: "checkout", fullUrl: 'basket/checkout'},
    ],
    pagesLK: [
        {id: 0, name: "Личный кабинет", url: "settings", fullUrl: 'settings'},
        {id: 1, name: "Профиль", url: "profile", fullUrl: 'settings/profile'},
        {id: 2, name: "Личные данные", url: "personaldata", fullUrl: 'settings/personaldata'},
        {id: 3, name: "История заказов", url: "history", fullUrl: 'settings/history'},
        {id: 4, name: "Подписки", url: "subscriptions", fullUrl: 'settings/subscriptions'},
        {id: 4, name: "Персональный менеджер", url: "personalmanager", fullUrl: 'settings/personalmanager'},
    ],
    pagesCatalog: [
        {id: 0, name: "Каталог", url: "catalog", fullUrl: 'catalog'},
        {id: 1, name: "Кровля", url: "krovlya", fullUrl: 'catalog/krovlya'},
        {id: 2, name: "Калькулятор кровли", url: "krovlyacalc", fullUrl: 'catalog/krovlya/krovlyacalc'},
    ],
    regions: [],
    accountType:[],

    payTypes: [
        {id: 10, name: 'Банковские карты'},
        {id: 20, name: 'Счет на оплату в банке'},
    ],
    orderStatuses: [
        {id: 10, name: "Новый", is_list: true},
        {id: 20, name: "Не подтвержден", is_list: true},
        {id: 30, name: "В производстве", is_list: true},
        {id: 40, name: "Готов к отгрузке", is_list: true},
        {id: 50, name: "Выполнен", is_list: true},
        {id: 60, name: "Черновик", is_list: false},
        {id: 70, name: "В обработке", is_list: false},
        {id: 80, name: "Подтвержден", is_list: false},
        {id: 90, name: "Оплачено", is_list: false},
        {id: 100, name: "Не оплачено", is_list: false},
        {id: 110, name: "В архиве", is_list: false},
        {id: 120, name: "Подтвердить отгрузку", is_list: false},
        {id: 130, name: "Отгружен", is_list: false},
        {id: 140, name: "Оформлен", is_list: false},
        {id: 150, name: "Отклонен", is_list: false},
    ],
    charakteristicList: [
        {id: 4, key: 'panel_view', name: 'Вид панели'},
        {id: 5, key: 'internal_coating', name: 'Внутреннее покрытие'},
        {id: 6, key: 'internal_thickness', name: 'Внутренняя толщина (мм.)'},
        {id: 7, key: 'overall_width', name: 'Габаритная ширина (мм.)'},
        {id: 10, key: 'slab_length', name: 'Длинна плиты'},
        {id: 13, key: 'insulation_thickness', name: 'Толщина утеплителя'},
        {id: 14, key: 'mounting_width', name: 'Монтажная ширина (мм.)'},
        {id: 17, key: 'mineral_wool_slab_volume', name: 'Объем плиты минваты'},
        {id: 18, key: 'coating', name: 'Покрытие'},
        {id: 19, key: 'insulation_density', name: 'Плотность утеплителя'},
        {id: 20, key: 'correction_factor', name: 'Поправочный коэффициент'},
        {id: 21, key: 'manufacturer', name: 'Производитель'},
        {id: 22, key: 'country_of_origin', name: 'Страна происхождения'},
        {id: 23, key: 'raw_materials_for_painting', name: 'Сырьё для покраски'},
        {id: 24, key: 'thickness', name: 'Толщина (мм.)'},
        {id: 25, key: 'slab_width', name: 'Ширина плиты'},
        {id: 26, key: 'density_minwool', name: 'Плотность минеральной ваты'},
        {id: 27, key: 'fire_resistance_limit', name: 'Предел огнестойкости'},
        {id: 28, key: 'apparent_resistance', name: 'Привиденное сопротивление'},
        {id: 29, key: 'profile_name', name: 'Профиль'},
        {id: 30, key: 'min_normative_length', name: 'Минимальная длина'},
        {id: 31, key: 'max_normative_length', name: 'Максимальная длина'},
    ],
    topLevelCategory: [],
    secondLevelCategory: [],
    regionCurrency: {
        643: {
            name: 'Россия',
            currency: 'руб',
            symbol: '₽',
        },
        398: {
            name: 'Казахстан',
            currency: 'тенге',
            symbol: '₸',
        },
    },
    spCardsMap: [
        {name: 'evosteel', id_1c: 'H2027', link: null},
        {name: 'eurolight', id_1c: 'H2028', link: null},
        {name: 'cordplus', id_1c: null, link: 'cardProduct/67428'},
    ],
    proflistCardsMap: [
        {name: 'С-8', id_1c: null, link: 'cardProduct/61273'},
        {name: 'С-10', id_1c: null, link: 'cardProduct/61270'},
        {name: 'ССМ-10', id_1c: null, link: 'cardProduct/61280'},
        {name: 'МП-20', id_1c: null, link: 'cardProduct/61288'},
        {name: 'С-21', id_1c: null, link: 'cardProduct/61278'},
        {name: 'С-25', id_1c: null, link: 'cardProduct/61278'},
        {name: 'НС-35', id_1c: null, link: 'cardProduct/61271'},
        {name: 'С-44', id_1c: null, link: 'cardProduct/61277'},
        {name: 'С-44', id_1c: null, link: 'cardProduct/61277'},
        {name: 'Н-60', id_1c: null, link: 'cardProduct/61287'},
        {name: 'Н-75', id_1c: null, link: 'cardProduct/61285'},
    ],
    bannersMap: [
        {id_1c: 'H2099', name: 'proflist', link: 'aboutcompany/info/proflist'},
        {id_1c: 'H2115', name: 'proflist', link: 'aboutcompany/info/proflist'},
        {id_1c: 'H2114', name: 'proflist', link: 'aboutcompany/info/proflist'},
        {id_1c: 'H2116', name: 'proflist', link: 'aboutcompany/info/proflist'},
        {id_1c: 'H2117', name: 'proflist', link: 'aboutcompany/info/proflist'},
    ],
    bookingStatuses: [
        {id: 10, name: "Забронировано"},
        {id: 20, name: "Cделка проиграна"},
        {id: 30, name: "Заключена сделка"},
        {id: 40, name: "Пересечение"},
        {id: 50, name: "Аннулирована"},
    ],
    companyTypes: [
        // {id: 0, name: 'Приглашение'},
        {id: 10, name: 'Ожидают ограниченный доступ'},
        {id: 20, name: 'Ожидают полный доступ'},
        {id: 30, name: 'Ограниченный доступ'},
        {id: 40, name: 'Полный доступ'},
        {id: 50, name: 'Администратор'},
        {id: 60, name: 'Архив'},
        {id: 70, name: 'Запрос доступа'},
    ],
}

const getters = {
    documentsCats: state => state.documentsCats,
    offersRegion: state => state.offersRegion,
    offersFrom: state => state.offersFrom,
    supportsType: state => state.supportsType,
    pagesDocuments: state => state.pagesDocuments,
    pagesPartner: state => state.pagesPartner,
    listBenefitTypes: state => state.listBenefitTypes,
    regions: state => state.regions,
    accountType: state => state.accountType,
    pagesServices: state => state.pagesServices,
    pagesLegals: state => state.pagesLegals,
    pagesSupports: state => state.pagesSupports,
    pagesAboutCompany: state => state.pagesAboutCompany,
    pagesBasket: state => state.pagesBasket,
    pagesLK: state => state.pagesLK,
    pagesCatalog: state => state.pagesCatalog,
    payTypes: state => state.payTypes,
    orderStatuses: state => state.orderStatuses,
    charakteristicList: state => state.charakteristicList,
    topLevelCategory: state => state.topLevelCategory,
    regionCurrency: state => state.regionCurrency,
    secondLevelCategory: state => state.secondLevelCategory,

    spCardsMap: state => state.spCardsMap,
    proflistCardsMap: state => state.proflistCardsMap,
    bannersMap: state => state.bannersMap,
    bookingStatuses: state => state.bookingStatuses,
    companyTypes: state => state.companyTypes,
}

const actions = {
    getDictionaries: ({commit}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + '/dictionaries/45', {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setDictionaries", response.data)
                    resolve(response)
                })
        })
    },
    getTopLevelCategory: ({commit}) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.get(Config.apiUrl + '/v1/nomenclature/top', {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setTopLevelCategory", response.data)
                    resolve(response)
                })
        })
    },
    getSecondCategory: ({commit}, payload) => {
        let id = (store.getters['auth/userCompanyId'] ? store.getters['auth/userCompanyId'] : store.getters['auth/localStorageCompanyId'])
        return new Promise((resolve) => {
            Http.post(Config.apiUrl + '/v1/nomenclature/search', payload, {headers: {'X-Company-ID': id}})
                .then(response => {
                    commit("setSecondLevelCategory", response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setDictionaries: (state, val) => {
        if (val.regions) {
            let array = []
            for (var key in val.regions) {
                array.push({
                    id: key,
                    name: val.regions[key],
                    text: val.regions[key]
                })
            }

            state.regions = array
        }
        if (val.accountType) {
            state.accountType = val.accountType
        }
    },
    setTopLevelCategory: (state, val) => {
        val.sort((a, b) => Number(a.id_1c.replace(/[^0-9]/g,"")) - Number(b.id_1c.replace(/[^0-9]/g,"")))
        state.topLevelCategory = val
    },
    setSecondLevelCategory: (state, val) => {
        state.secondLevelCategory = val
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
