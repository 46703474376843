<template>
    <div class="layout-section checkout-buyer-tab">
        <form class="form form--checkout" action="#" method="post" onsubmit="return false">
            <div class="form-fieldset">
                <div class="form-group">
                    <div class="form-item">
                        <p>Введите данные</p>
                    </div>
                </div>

                <div v-if="currentCompanies.length != 0" class="custom_select">
                    <div @click="openedCustomSelect = !openedCustomSelect" class="choosen_item">
                        <p>{{ selectedCompany.name ? selectedCompany.name : 'Выберите компанию' }}</p>

                        <svg v-if="!openedCustomSelect" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z" fill="#353B48"/>
                        </svg>

                        <svg v-if="openedCustomSelect" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071Z" fill="#353B48"/>
                        </svg>
                    </div>
                    <div v-if="openedCustomSelect" @mouseleave="openedCustomSelect = false" class="items">
                        <p @click="setCompanyOrder({})">Выберите компанию</p>
                        <p
                            v-for="com, idx in currentCompanies"
                            :key="idx"
                            @click="setCompanyOrder(com)"
                        >
                            {{ com.name }}
                        </p>
                    </div>
                </div>

                <!-- <div class="form-group form-row" v-if="order.account_type == 30">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Название компании</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.organization_name" 
                                    type="text" 
                                    placeholder="Введите название компании" 
                                    @keyup="update"
                                    :disabled="!isCanChangeOrgName"   
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Адрес компании</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.organization_address" 
                                    type="text" 
                                    placeholder="Введите адрес компании" 
                                    @keyup="update"
                                    :disabled="!isCanChangeOrgAddress" 
                                >
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group form-row" v-if="order.account_type == 30">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">ИНН</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.inn" 
                                    type="text" 
                                    placeholder="Введите ИНН" 
                                    @keyup="update"
                                    :disabled="!isCanChangeInn"     
                                >
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">КПП</div>
                            <div class="field-input">
                                <input 
                                    v-model="editOrder.kpp" 
                                    type="text" 
                                    placeholder="Введите КПП" 
                                    @keyup="update"
                                    :disabled="!isCanChangeKpp"     
                                >
                            </div>
                        </div>
                    </div>
                </div> -->
                
                <div class="form-group form-row">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">{{ (order.account_type == 20 ? 'Контактное лицо *' : 'ФИО *') }}</div>
                            <div class="field-input">
                                <input :class="{'error_input' : isIsset('fio', errorArray)}" v-model="editOrder.fio" type="text" :placeholder="(order.account_type == 20 ? 'Введите контактное лицо' : 'Введите ФИО')" @keyup="update" @change="checkError('fio')">
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">E-mail *</div>
                            <div class="field-input">
                                <input :class="{'error_input' : isIsset('email', errorArray)}" v-model="editOrder.email" type="email" placeholder="Введите E-mail" @keyup="update" @change="checkError('email')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group form-row">
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Телефон *</div>
                            <div class="field-input">
                                <the-mask :class="{'error_input' : isIsset('phone', errorArray)}" v-model="editOrder.phone" mask="+7 (###) ###-####" placeholder="Phone number" type="tel"  @keyup="update" @keydown.native="checkError('phone')"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-item">
                        <div class="field">
                            <div class="field-label">Комментарий к заказу</div>
                            <div class="field-input">
                                <input v-model="editOrder.comment" type="text" placeholder="Например, доставка после 2-х" @keyup="update">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="order.account_type != 30"     
                class="form-fieldset form-fieldset--medium"
            >
                <div class="form-group">
                    <div class="form-item">
                        <label class="checkbox-plus">
                            <input v-model="isAgree" type="checkbox" name="privacy">
                            <b></b>
                            <span>
                                Согласен (согласна) на обработку моих персональных данных.
                                <br> Ознакомлен с 
                                <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/BeEwJiJ_Jc.pdf" target="_blank">Политикой</a>
                                и
                                <a href="https://storage.yandexcloud.net/dev-mayakmetall/images/ZhlKe-a2dH.pdf" target="_blank">согласием</a>
                                на обработку персональных данных
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="form-fieldset form-fieldset--medium">
                <div class="form-group">
                    <div class="form-item">
                        <ul class="list list--flex layout-buttons">
                            <li class="list-item">
                                <button class="button button--primary" :disabled="(!isAgree && order.account_type != 30) || !isCanOrder" @click="registry">Оформить заказ</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
        <text-block />
    </div>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'

import Functions from '@/utils/functions'
import textBlock from '../text-block.vue'

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
    mixins: [Functions],
    components: {
        textBlock,
    },
    data () {
        return {
            isAgree: false,
            requireValues: [
                'phone',
                'email',
                'fio',
            ],
            errorArray: [],
            isCanChangeOrgName: true,
            isCanChangeOrgAddress: true,
            isCanChangeInn: true,
            isCanChangeKpp: true,
            selectedCompany: {},
            openedCustomSelect: false,
        }
    },
    watch: {
        currentCompany() {
            console.log('current company setted')
            this.setValuesFromCompany()
        }
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/order',
            user: 'auth/user',
            userCompanyId: 'auth/userCompanyId',
            companies: 'companies/companies',
            currentCompanies: 'companies/currentCompanies',
            searchCompanyForm: 'basket/searchForm',
        }),
        editOrder() {
            return Object.assign({}, this.order)
        },
        fullSum() {
            let sum = 0

            if (this.order.positions.length != 0) {
                this.order.positions.map((element) => {
                    sum += element.sell
                })
            }

            return sum
        },
        isCanOrder() {
            return this.validation()
        },
        currentCompany() {
            let company = null
            this.companies.map((element) => {
                if (element.id == this.userCompanyId) {
                    company = element
                }
            })
            return company
        },
    },
    mounted() {
        if (this.currentCompany) {
            this.setValuesFromCompany()
        }
        this.searchCompany()
    },
    methods: {
        ...Vuex.mapActions({
            updateOrder: 'basket/updateOrder',
            orderRegistration: 'basket/orderRegistration',
            searchCompanyStore: 'companies/search',
        }),
        ...Vuex.mapMutations({
            changeOrder: 'basket/changeOrder',
            setOrder: 'basket/setOrder',
            setFinishedOrder: 'basket/setFinishedOrder',
            setShowSuccessCreateOrder: 'app/setShowSuccessCreateOrder',
        }),
        searchCompany() {
            this.searchCompanyStore(this.searchCompanyForm)
        },
        setCompanyOrder(com) {
            this.selectedCompany = com
            this.editOrder.organization_name = com.name
            this.editOrder.organization_address = com.adress
            this.editOrder.inn = com.inn
            this.editOrder.kpp = com.kpp
            this.openedCustomSelect = false
        },
        setValuesFromCompany() {
            if (this.currentCompany.name) {
                this.editOrder.organization_name = this.currentCompany.name
                this.isCanChangeOrgName = false
            }else{
                this.editOrder.organization_name = null
                this.isCanChangeOrgName = TextTrackCueList
            }
            if (this.currentCompany.adress) {
                this.editOrder.organization_address = this.currentCompany.adress
                this.isCanChangeOrgAddress = false
            }else{
                this.editOrder.organization_address = null
                this.isCanChangeOrgAddress = true
            }
            if (this.currentCompany.inn) {
                this.editOrder.inn = this.currentCompany.inn
                this.isCanChangeInn = false
            }
            else{
                this.editOrder.inn = null
                this.isCanChangeInn = true
            }
            if (this.currentCompany.kpp) {
                this.editOrder.kpp = this.currentCompany.kpp
                this.isCanChangeKpp = false
            }else{
                this.editOrder.kpp = null
                this.isCanChangeKpp = true
            }
            this.update()
        },
        checkError(val) {
            if (this.editOrder[val] != null && this.editOrder[val] != '' || (val == 'phone' && !this.checkPhoneValid()))  {
                this.errorArray.map((element, idx) => {
                    if (element == val) {
                        this.errorArray.splice(idx, 1)
                    }
                })
            }
        },
        isIsset(val, array) {
            let isset = false
            array.map((element) => {
                if (element == val) {
                    isset = true
                }
            })
            return isset
        },
        checkPhoneValid() {
            let isValid = true
            if (this.editOrder.phone == null || this.editOrder.phone == '' || this.editOrder.phone.length < 10) {
                isValid = false
            }
            return isValid
        },
        validation() {
            let isCan = true
            this.requireValues.map((element) => {
                if (this.editOrder[element] == null || this.editOrder[element] == '' || (element == 'phone' && !this.checkPhoneValid())) {
                    isCan = false
                    if (!this.isIsset(element, this.errorArray)) {
                        this.errorArray.push(element)
                    }
                }
            })
            return isCan
        },
        update() {
            this.changeOrder(this.editOrder)
        },
        goToLastStep() {
            this.updateOrder(this.editOrder)
                .then(() => {
                    this.push('basket/checkout/last-step')
                })
        },
        getFullName(surname, name, midname) {
            let full = ''
            if (surname) {
                full += surname + ' '
            }
            if (name) {
                full += name + ' '
            }
            if (midname) {
                full += midname
            }
            return full
        },
        registry() {
            if (!this.validation()) {
                return
            }
            this.editOrder.company_id = Number(this.userCompanyId)
            this.updateOrder(this.editOrder)
                .then(() => {
                    this.orderRegistration()
                        .then(() => {
                            this.setFinishedOrder({
                                pay_type: this.order.pay_type,
                                sum: this.fullSum,
                            }) 
                            this.setOrder({
                                id: null,
                                positions: [],
                                
                                account_type: null,
                                location: null,
                                postcode: null,
        
                                delivery_type: null,
                                pay_type: null,
        
                                organization_name: null,
                                organization_address: null,
                                inn: null,
                                kpp: null,
        
                                fio: null,
                                email:null,
                                phone: null,
                                comment: null,
                            })
                            if (localStorage.mayakOrderId) {
                                localStorage.removeItem("mayakOrderId")
                            }                   
                            this.push('basket/checkout_finish')
                            this.setShowSuccessCreateOrder(true)
                        })
                })
        },
    }
}
</script>
<style lang="scss">
    .checkout-buyer-tab{
        .custom_select{
            position: relative;
            z-index: 100;
            height: 100%;
            width: 32%;
            margin: 20px 0;

            .choosen_item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                padding: 2px 12px;
                height: 100%;

                &:hover{
                    cursor: pointer;
                }

                p{
                    margin-bottom: 0;
                    color: #757575;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            .items{
                position: absolute;
                border: 1px solid #D6D6D6;
                border-radius: 10px;
                background-color: #fff;
                max-height: 300px;
                overflow-x: auto;

                p{
                    padding: 6px 12px;
                    margin-bottom: 0;
                    color: #353B48;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;

                    &:hover{
                        background-color: #eaeaea;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>