<template>
    <div class="order-table">
        <div class="order-table_headers">
            <p style="width: 23%;">Состав по номенклатуре</p>
            <p style="width: 9%; text-align: center;">Ед.изм.</p>
            <p style="width: 9%; text-align: center;">Кол-во</p>
            <p style="width: 16%; text-align: center;">В производстве</p>
            <p style="width: 10%; text-align: center;">На складе</p>
            <p style="width: 9%; text-align: center;">Отгружены</p>
            <p style="width: 9%; text-align: center;">Остаток</p>
            <p style="width: 9%; text-align: center;">Цена</p>
            <p style="width: 9%; text-align: center;">Сумма</p>
        </div>
        <div class="order-table_rows">
            <div v-if="!isEmptyObject(positionsMap)">
                <div 
                    v-for="position, idx in positionsMap"
                    :key="idx"
                    class="order-table_rows-single"
                    :style="idx % 2 !== 1 ? 'background-color: #FAFAFA;' : ''"
                >
                    <div class="details">
                        <div style="width: 23%; font-weight: 700;">{{ position.name }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.base_unit_of_measure }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.count_base_unit_of_measure }}</div>
                        <div style="width: 16%; text-align: center;">{{ position.amount_in_progress ? position.amount_in_progress : 0 }}</div>
                        <div style="width: 10%; text-align: center;">{{ position.amount_in_stock ? position.amount_in_stock : 0 }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.amount_shipped ? position.amount_shipped : 0 }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.amount_balance ? position.amount_balance : 0 }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.price }}</div>
                        <div style="width: 9%; text-align: center; font-weight: 700;">{{ position.sell }}</div>
                    </div>

                    <div @click="changeOpenDetails(idx)" class="order-table_bottom">
                        <svg width="1290" height="2" style="padding-left: 4px;" viewBox="0 0 1290 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="1290" y1="1" y2="1" stroke="black" stroke-width="2"/>
                        </svg>

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1_23537)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7083 15.7064C12.5208 15.8938 12.2665 15.9992 12.0013 15.9992C11.7362 15.9992 11.4818 15.8938 11.2943 15.7064L5.63732 10.0494C5.54181 9.95712 5.46563 9.84678 5.41322 9.72477C5.36081 9.60277 5.33322 9.47155 5.33207 9.33877C5.33092 9.20599 5.35622 9.07431 5.4065 8.95141C5.45678 8.82852 5.53103 8.71686 5.62492 8.62297C5.71882 8.52908 5.83047 8.45483 5.95337 8.40454C6.07626 8.35426 6.20794 8.32896 6.34072 8.33012C6.4735 8.33127 6.60472 8.35886 6.72672 8.41126C6.84873 8.46367 6.95907 8.53986 7.05132 8.63537L12.0013 13.5854L16.9513 8.63537C17.1399 8.45321 17.3925 8.35241 17.6547 8.35469C17.9169 8.35697 18.1677 8.46214 18.3531 8.64755C18.5385 8.83296 18.6437 9.08377 18.646 9.34597C18.6483 9.60816 18.5475 9.86076 18.3653 10.0494L12.7083 15.7064Z" fill="black"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1_23537">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <p style="width: 14rem;">Детали заказа</p>
                    </div>
                    
                    <div v-if="indexShowestDetails == idx && position" class="subpositions_details" style="background-color: #fff;">
                        <div 
                            v-for="subonce, index in position.details"
                            :key="index"
                            class="subpositions_details_once"
                        >
                            <div style="width: 23%;">{{ subonce.name }}</div>
                            <div style="width: 9%; text-align: center;">{{ subonce.base_unit_of_measure }}</div>
                            <div style="width: 9%; text-align: center;">{{ subonce.count_base_unit_of_measure }}</div>
                            <div style="width: 16%; text-align: center;">{{ subonce.amount_in_progress ? subonce.amount_in_progress : 0 }}</div>
                            <div style="width: 10%; text-align: center;">{{ subonce.amount_in_stock ? subonce.amount_in_stock : 0 }}</div>
                            <div style="width: 9%; text-align: center;">{{ subonce.amount_shipped ? subonce.amount_shipped : 0 }}</div>
                            <div style="width: 9%; text-align: center;">{{ subonce.amount_balance ? subonce.amount_balance : 0 }}</div>
                            <div style="width: 9%; text-align: center;">{{ subonce.price }}</div>
                            <div style="width: 9%; text-align: center; font-weight: 700;">{{ subonce.sell }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div 
                    v-for="position, idx in leftPositions"
                    :key="idx"
                    class="order-table_rows-single"
                    :style="idx % 2 !== 1 ? 'background-color: #FAFAFA;' : ''"
                >
                    <div class="details">
                        <div style="width: 23%; font-weight: 700;">{{ getFullNamePosition(position) }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.base_unit_of_measure }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.count_base_unit_of_measure }}</div>
                        <div style="width: 16%; text-align: center;">{{ position.amount_in_progress ? position.amount_in_progress : 0 }}</div>
                        <div style="width: 10%; text-align: center;">{{ position.amount_in_stock ? position.amount_in_stock : 0 }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.amount_shipped ? position.amount_shipped : 0 }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.amount_balance ? position.amount_balance : 0 }}</div>
                        <div style="width: 9%; text-align: center;">{{ position.price }}</div>
                        <div style="width: 9%; text-align: center; font-weight: 700;">{{ position.sell }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    props: {
        positionsMap: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return{
            indexShowestDetails: null,
            leftPositions: [],
        } 
    },
    computed: {
        ...Vuex.mapGetters({
            order: 'basket/currentOrder',
        }),
    },
    mounted() {
        setTimeout(() => {
            this.checkIssetPos()
        }, 1000)
    },
    methods: {
        changeOpenDetails(index) {
            if (this.indexShowestDetails == index) {
                this.indexShowestDetails = null
            }else{
                this.indexShowestDetails = index
            }
        },
        getFirstPositionName(name) {
            let splited = name.split(' ')
            if (splited[0] == '' || splited[0] == ' ') {
                return splited[1] + ' ' + splited[2]
            }else{
                return splited[0] + ' ' + splited[1]
            }
        },
        getFullNamePosition(pos) {
            let full_name = ''
            if (this.getFirstPositionName(pos.name)) {
                full_name += this.getFirstPositionName(pos.name)
            }
            if (pos.length) {
                full_name += ', Длина: ' + pos.length
            }
            if (pos.coating) {
                full_name += ', ' + pos.coating
            }
            if (pos.color_inner) {
                full_name += ', Цвет: ' + pos.color_inner
            }else if (pos.color_external) {
                full_name += ', Цвет: ' + pos.color_external
            }
            return full_name
        },
        checkIssetPos() {
            this.order.positions.map((element) => {
                if (!this.positionsMap[element.id_1c]) {
                    this.leftPositions.push(element)
                }
            })
        },
    },
}
</script>